import React from 'react';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

import PanelDetailsHeader from './components/PanelDetailsHeader';
import PanelDetailsParent from './components/PanelDetailsParent';

export type ParentDetailsMapping = {
    translation: string;
    value: string;
};

type Props = {
    onClose: () => void;
    open: boolean;
    valuesToDisplay: ParentDetailsMapping[];
    headerTitle: string;
};

const StyDrawer = styled(Drawer, {
    name: 'StyDrawer',
})({
    '& .MuiDrawer-paper': {
        width: '35%',
        maxWidth: '700px',
    },
    /* with MUI5, Modal have a default higher z-index than the drawer (1300 vs 1200);
     * details panel is therefore hidden without this override */
    zIndex: 1400,
});

export default function PanelDetails({
    open,
    onClose,
    valuesToDisplay,
    headerTitle,
}: Props): React.ReactElement {
    const theme = useTheme();
    return (
        <StyDrawer anchor="right" open={open} onClose={onClose}>
            <Box sx={{ margin: theme.spacing(5) }}>
                <PanelDetailsHeader title={headerTitle} onClose={onClose} />
                <PanelDetailsParent valuesToDisplay={valuesToDisplay} />
            </Box>
        </StyDrawer>
    );
}
